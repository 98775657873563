<template>
    <div class="pc-container">
        <Nav :curIndex="0"></Nav>
        <div class="main">
            <div class="box1">
                <div class="box1-item">
                    <div class="label">{{$t('Account.Account')}}</div>
                    <div class="value">{{userInfo.email}}</div>
                </div>
                <div class="box1-item">
                    <div class="label">ID</div>
                    <div class="value">{{userInfo.id}}</div>
                </div>
            </div>
            <div class="box2">
                <div class="box2-item">
                    <div class="label">{{$t('Account.LoginPassword')}}</div>
                    <div class="btn" @click="to('/account/ChangeLoginPassword')">{{$t('Account.Modify')}}</div>
                </div>
                <div class="box2-item">
                    <div class="label">{{$t('Account.TransactionPassword')}}</div>
                    <div v-if="!userInfo.is_pay_pass" class="btn" @click="to('/account/ChangeTransactionPassword/0')">{{$t('Account.SetTransactionPassword')}}</div>
                    <div v-else class="btn" @click="to('/account/ChangeTransactionPassword/1')">{{$t('Account.ResetTransactionPassword')}}</div>
                </div>
            </div>
            <div class="box3">
                <div class="box3-item">
                    <div class="item-title">{{$t('Account.Recommend')}}</div>
                    <div class="info-box">
                        <div class="info-item">
                            <i :class="userInfo.phone ? 'el-icon-success' : 'el-icon-error'"></i>
                            {{$t('Account.MobileAuth')}}
                        </div>
                        <div class="info-item">
                            <i :class="userInfo.email ? 'el-icon-success' : 'el-icon-error'"></i>
                            {{$t('Account.BindMailbox')}}
                        </div>
                        <div class="info-item">
                            <i :class="userInfo.isgoogle ? 'el-icon-success' : 'el-icon-error'"></i>
                            {{$t('Account.GoogleAuth')}}
                        </div>
                        <div class="info-item">
                            <i :class="userInfo.idStatus == 1 ? 'el-icon-success' : 'el-icon-error'"></i>
                            {{$t('Account.KYCCertification')}}
                        </div>
                    </div>
                </div>
                <div class="box3-item">
                    <div class="item-title">{{$t('Account.MobileAuth')}} {{userInfo.phone}}</div>
                    <div class="item-desc">{{$t('Account.MobileAuthDesc')}}</div>
                </div>
                <div class="box3-item">
                    <div class="item-title">{{$t('Account.BindMailbox')}} {{userInfo.email}}</div>
                    <div class="item-desc">{{$t('Account.BindMailboxDesc')}}</div>
                </div>
                <div class="box3-item">
                    <div class="item-title">{{$t('Account.GoogleAuth')}}</div>
                    <div class="item-info">
                        <div class="desc">{{$t('Account.GoogleAuthDesc')}}</div>
                        <div v-if="!userInfo.isgoogle" class="btn" @click="to('/account/GoogleAuth/0')">{{$t('Account.Add')}}</div>
                        <div v-else class="btn" @click="to('/account/GoogleAuth/1')">{{$t('Account.Remove')}}</div>
                    </div>
                </div>
                <div class="kyc-item">
                    <div class="item-title">{{$t('Account.KYCCertification')}}</div>
                    <div v-if="userInfo.idStatus == -1" class="btn" @click="to('/account/KYCAuth')">{{$t('Account.GoAuth')}}</div>
                    <div v-else class="status" :style="{color: userInfo.idStatus == 1 ? '#F08834' : '#6D6F7E'}">
                        {{userInfo.idStatus == 1 ? $t('Account.Verified') : $t('Account.UnderReview')}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            Nav: () => import('./nav.vue')
        },
        data() {
            return {
                userInfo: {
                    email: '',
                    phone: '',
                    id: '',
                    idStatus: -1,
                    isgoogle: false,
                    is_pay_pass: false
                }
            }
        },
        created() {
            this.getUserInfo()
        },
        methods: {
            to(path) {
                this.$router.push({
                    path
                })
            },
            
            getUserInfo() {
                this.$api.user_info().then(res => {
                    this.userInfo = res.data
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .pc-container {
        display: flex;
        width: 100%;
        box-sizing: border-box;
        padding: 25px $box-padding 40px;
        background-color: #f6f6f6;
        
        .main {
            width: $main-width;
            
            .box1 {
                width: 100%;
                box-sizing: border-box;
                padding: 32px;
                margin-bottom: 25px;
                background-color: #FFFFFF;
                
                .box1-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    
                    &:not(:last-child) {
                        margin-bottom: 32px;
                    }
                    
                    .label {
                        max-width: 48%;
                        font-size: 15px;
                        color: #000000;
                        word-wrap: break-word;
                    }
                    
                    .value {
                        max-width: 48%;
                        font-size: 15px;
                        color: #000000;
                        word-break: break-all;
                    }
                }
            }
            
            .box2 {
                width: 100%;
                box-sizing: border-box;
                padding: 32px;
                margin-bottom: 25px;
                background-color: #FFFFFF;
                
                .box2-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    
                    &:not(:last-child) {
                        margin-bottom: 32px;
                    }
                    
                    .label {
                        max-width: 48%;
                        font-size: 15px;
                        color: #000000;
                        word-wrap: break-word;
                    }
                    
                    .btn {
                        max-width: 48%;
                        height: 35px;
                        line-height: 35px;
                        box-sizing: border-box;
                        padding: 0 16px;
                        border-radius: 4px;
                        font-size: 15px;
                        color: #6D6F7E;
                        text-align: center;
                        white-space: nowrap;
                        background-color: #E2E3ED;
                        cursor: pointer;
                        
                        &:hover {
                            color: #FFFFFF;
                            background-color: #F08834;
                        }
                    }
                }
            }
            
            .box3 {
                width: 100%;
                box-sizing: border-box;
                padding: 0 32px 32px;
                background-color: #FFFFFF;
                
                .box3-item {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 32px 0;
                    border-bottom: 1px solid #E9E9E9;
                    
                    .item-title {
                        width: 100%;
                        margin-bottom: 32px;
                        font-size: 15px;
                        color: #000000;
                        word-wrap: break-word;
                    }
                    
                    .info-box {
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        
                        .info-item {
                            display: flex;
                            align-items: center;
                            margin:  0 64px 4px 0;
                            font-size: 15px;
                            color: #000000;
                            white-space: nowrap;
                            cursor: default;
                            
                            .el-icon-success, 
                            .el-icon-error
                             {
                                margin-right: 8px;
                                font-size: 22px;
                            }
                            
                            .el-icon-success {
                                color: #01BD8B;
                            }
                            
                            .el-icon-error {
                                color: #FB474E;
                            }
                        }
                    }
                    
                    .item-desc {
                        width: 100%;
                        box-sizing: border-box;
                        padding: 0 32px;
                        font-size: 15px;
                        color: #6D6F7E;
                        word-wrap: break-word;
                    }
                    
                    .item-info {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        box-sizing: border-box;
                        padding: 0 0 0 32px;
                        
                        .desc {
                            max-width: 100%;
                            font-size: 15px;
                            color: #6D6F7E;
                            word-wrap: break-word;
                        }
                        
                        .btn {
                            height: 35px;
                            line-height: 35px;
                            box-sizing: border-box;
                            padding: 0 16px;
                            margin-left: 32px;
                            border-radius: 4px;
                            font-size: 15px;
                            color: #6D6F7E;
                            text-align: center;
                            white-space: nowrap;
                            background-color: #E2E3ED;
                            cursor: pointer;
                            
                            &:hover {
                                color: #FFFFFF;
                                background-color: #F08834;
                            }
                        }
                    }
                }
                
                .kyc-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 32px 0;
                    
                    .item-title {
                        max-width: 100%;
                        font-size: 15px;
                        color: #000000;
                        word-wrap: break-word;
                    }
                    
                    .btn {
                        height: 35px;
                        line-height: 35px;
                        box-sizing: border-box;
                        padding: 0 16px;
                        margin-left: 32px;
                        border-radius: 4px;
                        font-size: 15px;
                        color: #6D6F7E;
                        text-align: center;
                        white-space: nowrap;
                        background-color: #E2E3ED;
                        cursor: pointer;
                        
                        &:hover {
                            color: #FFFFFF;
                            background-color: #F08834;
                        }
                    }
                    
                    .status {
                        margin-left: 32px;
                        font-size: 15px;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
</style>